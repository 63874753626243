@use "@angular/material" as mat;


@mixin apply-theme($theme) {
    @if (mat.get-theme-type($theme) == light) {
        #ps-logo {
            filter: invert(1);
        }
    } @else if (mat.get-theme-type($theme) == dark) {
        #ps-logo {
            filter: invert(0);
        }
    }
}
