/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use "app/components/view-all-pto-days/calendar-view/calendar-view-theme" as
    calendar;
@use "app/components/navbar/navbar-theme" as appNavbar;
@use "m3-theme.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
}

::-webkit-scrollbar-track {
    background: var(--sys-surface-container-lowest);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background: var(--sys-outline);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--sys-outline-variant);
}

@mixin theme-color-variants($theme, $variant) {
    .m3-#{$variant} {
        @include mat.badge-color($theme, $color-variant: $variant);
        @include mat.button-color($theme, $color-variant: $variant);
        //@include mat.button-toggle-color($theme, $color-variant: $variant);
        @include mat.checkbox-color($theme, $color-variant: $variant);
        @include mat.chips-color($theme, $color-variant: $variant);
        @include mat.datepicker-color($theme, $color-variant: $variant);
        @if ($variant != error) {
            @include mat.fab-color($theme, $color-variant: $variant);
        }
        @include mat.form-field-color($theme, $color-variant: $variant);
        @include mat.icon-color($theme, $color-variant: $variant);
        @include mat.option-color($theme, $color-variant: $variant);
        @include mat.progress-bar-color($theme, $color-variant: $variant);
        @include mat.progress-spinner-color($theme, $color-variant: $variant);
        @include mat.pseudo-checkbox-color($theme, $color-variant: $variant);
        @include mat.radio-color($theme, $color-variant: $variant);
        @include mat.select-color($theme, $color-variant: $variant);
        @include mat.slide-toggle-color($theme, $color-variant: $variant);
        @include mat.slider-color($theme, $color-variant: $variant);
        @include mat.stepper-color($theme, $color-variant: $variant);
        @include mat.tabs-color($theme, $color-variant: $variant);
        @include mat.toolbar-color($theme);
    }
}

// ide hint for autocomplete, mines not picking up the sass generation
.m3-primary {
}

.m3-secondary {
}

.m3-tertiary {
}

.m3-error {
}

.m3-success {
    --mdc-chip-elevated-selected-container-color: var(--my-success-container);
    --mdc-chip-selected-label-text-color: var(--my-on-success-container);
}

.m3-caution {
    --mdc-chip-elevated-selected-container-color: var(--my-caution-container);
    --mdc-chip-selected-label-text-color: var(--my-on-caution-container);
}

body {
    // Fix weird scrollbar when a button or something is at the bottom of the dialog content
    --mat-dialog-with-actions-content-padding: var(--mat-dialog-content-padding)
        4px;

    @include mat.system-level-colors(m3-theme.$light-theme);
    @include mat.all-component-themes(m3-theme.$light-theme);
    @include theme-color-variants(m3-theme.$light-theme, primary);
    @include theme-color-variants(m3-theme.$light-theme, secondary);
    @include theme-color-variants(m3-theme.$light-theme, tertiary);
    @include theme-color-variants(m3-theme.$light-theme, error);
    @include calendar.apply-theme(m3-theme.$light-theme);
    @include appNavbar.apply-theme(m3-theme.$light-theme);
}

.light-theme {
    input {
        color-scheme: only light;
    }

    --my-border-color: rgba(0, 0, 0, 0.2);

    --my-success: #406836;
    --my-on-success: #ffffff;
    --my-success-container: #c0efb0;
    --my-on-success-container: #002200;

    --my-caution: #815512;
    --my-on-caution: #ffffff;
    --my-caution-container: #ffddb7;
    --my-on-caution-container: #2a1700;

    .selected-item {
        border: 1px solid var(--mat-primary, #4190ff);
        background-color: #2a1700;
    }
}

.dark-theme {
    --my-border-color: rgba(255, 255, 255, 0.2);

    --my-success: #a5d395;
    --my-on-success: #11380b;
    --my-success-container: #285020;
    --my-on-success-container: #c0efb0;

    --my-caution: #f7bb70;
    --my-on-caution: #462a00;
    --my-caution-container: #653e00;
    --my-on-caution-container: #ffddb7;

    @include mat.system-level-colors(m3-theme.$dark-theme);
    @include mat.all-component-colors(m3-theme.$dark-theme);
    @include theme-color-variants(m3-theme.$dark-theme, primary);
    @include theme-color-variants(m3-theme.$dark-theme, secondary);
    @include theme-color-variants(m3-theme.$dark-theme, tertiary);
    @include theme-color-variants(m3-theme.$dark-theme, error);
    @include calendar.apply-theme(m3-theme.$dark-theme);
    @include appNavbar.apply-theme(m3-theme.$dark-theme);

    input {
        color-scheme: only dark;
    }

    .selected-item {
        border: 1px solid var(--mat-primary, #5762ff);
    }
}
