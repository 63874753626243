@use "@angular/material" as mat;
@use "angular-calendar/scss/angular-calendar";

@mixin apply-theme($theme) {
    .cal-month-view {
        div.cal-cell-row:hover {
            background-color: inherit;
        }

        .cal-day-cell.cal-out-month .cal-day-number {
            opacity: 0.3;
        }

        .cal-event-title {
            cursor: default;

            &:hover {
                text-decoration: none;
            }
        }

        .cal-day-number {
            opacity: 1;
        }
    }

    @if (mat.get-theme-type($theme) == dark) {
        .cal-month-view {
            color: #e3e2e6; // on-surface

            .cal-day-number {
                color: #e3e2e6; // on-surface
            }
        }

        button.active {
            background-color: #3e2845; // on-tertiary
            color: #dcbce1; // tertiary
        }

        .date-container {
            color: #003062; // on-primary
        }

        .cal-day-badge {
            background-color: #e3e2e6 !important; // inverse-surface
            color: #2f3033 !important; // inverse-on-surface
        }

        @include angular-calendar.cal-theme(
            (
                border-color: #8e9099,
                // outline
                bg-primary: #1a1b1e,
                // surface
                bg-secondary: #1a1b1e,
                // surface
                today-bg: #343538,
                // surface-container-highest
                bg-active: #1e2023,
                // surface-container
                weekend-color: #e3e2e6,
                // on-surface
                badge-color: #e3e2e6,
                // inverse-surface
            )
        );
    } @else if (mat.get-theme-type($theme) == light) {
        .cal-month-view {
            color: #1a1b1e; // on-surface

            .cal-day-number {
                color: #1a1b1e; // on-surface
            }
        }

        button.active {
            background-color: #ffffff; // on-tertiary
            color: #6f5675; // tertiary
        }

        .date-container {
            color: #ffffff; // on-primary
        }

        .cal-day-badge {
            background-color: #2f3033 !important; // inverse-surface
            color: #f1f0f4 !important; // inverse-on-surface
        }

        @include angular-calendar.cal-theme(
            (
                border-color: #74777f,
                // outline
                bg-primary: #fdfbff,
                // surface
                bg-secondary: #fdfbff,
                // surface
                today-bg: #e3e2e6,
                // surface-container-highest
                bg-active: #eeedf1,
                // surface-container
                weekend-color: #1a1b1e,
                // on-surface
                badge-color: #e3e2e6,
                // inverse-surface
            )
        );
    }
}
